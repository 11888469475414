import React from 'react'
import './all.sass'

const List = ({ list }) => {
  return (
    <dl className="content">
      {list.map((listItem) => (
        <dd>{Object.values(listItem)[0]}</dd>
      ))}
    </dl>
  )
}

export default List
