import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import List from '../components/List'

export default function SoftwareMaintenancePlanTemplate({ data, pageContext }) {
  const { frontmatter } = data.markdownRemark
  const { filePath } = pageContext
  const filePathParts = filePath.split('/')
  const productName = filePathParts[0]
  const documentName = filePathParts[2]
  const safetyClassificationDescriptions = {
    A: 'No injury or damage to health is possible',
    B: 'Non-serious injury is possible.',
    C: 'Death or serious injury is possible.',
  }
  const safetyClass = frontmatter.softwareSafetyClassification
  return (
    <Layout title={documentName} filePath={filePath} tableOfContents="true">
      <div>
        <h1>1 Identification</h1>
        <p>
          This document describes the software maintenance plan for the for the
          {productName} device firmware. The safety class of this software is
          class {safetyClass}: {safetyClassificationDescriptions[safetyClass]}.
          This plan addresses the following:
        </p>
        <dl className="content">
          <dd>
            The activities to be used in the maintenance of the software system;
          </dd>
          <dd>The deliverables of each activity;</dd>
          <dd>
            Criteria for determining whether feedback is considered a problem;
          </dd>
          <dd>Use of the software RISK MANAGEMENT PROCESS;</dd>
          <dd>Use of the software problem resolution PROCESS; and</dd>
          <dd>Procedures to manage changes to SOUP.</dd>
        </dl>
      </div>
      <div>
        <h1>2 Definitions</h1>
        <p>
          Words in all capitals are defined in IEC 62304. These definitions are
          not recopied within this document.
        </p>
      </div>
      <div>
        <h1>3 Feedback Monitoring Process</h1>
        <p>
          The following ACTIVITIES will be used to implement the software
          feedback monitoring PROCESS for this product. The ACTIVITIES will be
          executed in sequential order for each feedback item received.
        </p>
        <h2>3.1 Feedback Receiving ACTIVITY</h2>
        <p>
          All feedback receive from customers will be recorded in the RMA system
          according to document number 10002. No distinction will be made at
          this point between SYSTEM issues and software issues.
        </p>
        <h2>3.2 Feedback Evaluation ACTIVITY</h2>
        <p>
          All feedback received from customers will be evaluated within the RMA
          system according to document number 10005. Feedback that requires a
          software change shall result in the creation of a problem report
          document in the document management system. Results of the
          investigation shall be recorded using the RMA system, including
          references to any generated problem report documents.
        </p>
        <h2>3.3 Feedback Resolution ACTIVITY</h2>
        <p>
          All feedback shall be resolved in the RMA system according to document
          number 10005, with all attached problem reports being resolved prior
          to resolution of RMA system contact report.
        </p>
      </div>
      <div>
        <h1>4 Problem Resolution Process</h1>
        <p>
          The following ACTIVITIES will be used to implement the software
          problem resolution PROCESS for this product. The ACTIVITIES will be
          executed in sequential order for each problem report.
        </p>
        <h2>4.1 Problem Investigation ACTIVITY</h2>
        <p>
          Each problem report generated by the feedback monitoring PROCESS shall
          be evaluated for its impact on the safety of the system and if a
          change to the software is required. If no change is required, the
          problem report shall be updated with the findings and submitted to the
          problem resolution ACTIVITY. If a change is required, the problem
          report shall be updated with the findings and submitted to the problem
          notification ACTIVITY.
        </p>
        <h2>4.2 Problem Notification ACTIVITY</h2>
        <p>
          The Project Manager shall determine the relevant parties affected by
          the issue identified in the problem report. This project Manager shall
          notify such parties of the existence of the problem once the problem
          investigation has been completed.
        </p>
        <h2>4.3 Problem Trend Analysis ACTIVITY</h2>
        <p>
          Once the problem investigation ACTIVITY has been completed, the
          software problem report shall be summarized in a database of software
          problem reports for the system, and other recent problem reports shall
          be compared to the most recent problem report for any emerging trends.
        </p>
        <h2>4.4 Problem Resolution ACTIVITY</h2>
        <p>
          Once the problem investigation ACTIVITY has been completed, the
          software problem report shall be summarized in a database of software
          problem reports for the system, and other recent problem reports shall
          be compared to the most recent problem report for any emerging trends.
        </p>
      </div>
      <div>
        <h1>5 SOUP Maintenance PROCESS</h1>
        <p>
          The following ACTIVITIES will be used to implement the software SOUP
          maintenance PROCESS for this product. The ACTIVITIES will be executed
          in sequential order for each SOUP change.
        </p>
      </div>
      <div>
        <h1>6 SOUP Change Monitoring</h1>
        <p>
          Any SOUP used in this software system shall be reviewed every 3 months
          for upgrades, bug fixes, patches, and obsolescence. Any change shall
          result in creation of a problem report in the document management
          system.
        </p>
      </div>
      <div>
        <h1>7 Responsibilities</h1>
        <p>
          The software development team will be comprised of the following
          members:
        </p>
        <h2>7.1 Project Manager</h2>
        <p>
          The project manager shall be responsible for overseeing the processes
          defined in this plan.
        </p>
        <h2>7.2 Test Engineer</h2>
        <p>
          The test engineer shall be responsible for the problem investigation
          and SOUP change analysis activities.
        </p>
        <h2>7.3 Quality Management</h2>
        <p>
          Quality Management shall manage change control activities and ensure
          that applicable standards are current and available.
        </p>
        <h2>7.4 Additional Support Persons</h2>
        <p>
          Additional support persons shall be added to the team as needed to
          participate in milestone reviews, assist with code implementation, and
          assist with document generation.
        </p>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MarkdownQuerySoftwareMaintenance($filePath: String) {
    markdownRemark(fields: { filePath: { eq: $filePath } }) {
      frontmatter {
        softwareSafetyClassification
      }
    }
  }
`
